<template>
  <div class="dash">
    <section id="heading" class="container">
      <h2>
        my configs
        <small>manage your cloud configs</small>
      </h2>
    </section>

    <section id="private" class="container article">
      <div class="table-responsive card fluid">
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>updated</th>
              <th>downloads</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(config, index) in this.myConfigs" :key="index">
              <td>
                {{ config.name }}
              </td>

              <td>
                {{ config.updated_at }}
              </td>

              <td>
                {{ config.downloads }}
              </td>

              <td>
                <button
                  class="action_edit"
                  disabled
                  @click.prevent="editConfig(config.id)"
                  v-text="'edit'"
                />

                <button
                  class="action_edit action_delete"
                  disabled
                  @click.prevent="deleteConfig(config.id)"
                  v-text="'delete'"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section id="heading" class="container">
      <h2>
        community configs
        <small>see the public configs of other users</small>
      </h2>
    </section>

    <section id="public" class="container article">
      <div class="table-responsive card fluid">
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>author</th>
              <th>updated</th>
              <th>downloads</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(config, index) in this.configs" :key="index">
              <td>
                {{ config.name }}
              </td>

              <td>
                <a class="preview_user" @click.prevent="redirectProfile(config.author_id)">{{ config.author.name }}</a>

                <div class="preview_user_content">
                  <img :src="config.author.avatar" alt="xanax666 avatar" />

                  <h3 :class="{ span_admin: config.author.role == 666 }">
                    {{ config.author.name }}
                  </h3>

                  <span>
                    {{ config.author.sub }}
                  </span>
                </div>
              </td>

              <td>
                {{ config.updated_at }}
              </td>

              <td>
                {{ config.downloads }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import "@/assets/css/dash.css";
import "@/assets/css/subs.css";
import request from "@/utils/axios";

export default {
  name: "Cloud",
  data() {
    return {
      user: Object,
      configs: [],
      myConfigs: [],
    };
  },
  methods: {
    redirectProfile(userId) {
      this.$router.push('/profile/' + userId);
    }
  },
  created() {
    this.user = this.$store.getters.getUser;

    request.get("/cheat/configs").then((response) => {
      let configs = response.data.configs;

      this.configs = configs;
      this.myConfigs = configs.filter((config) => {
        return config.author_id === this.user.id;
      });
    });
  },
};
</script>

<style scoped>
</style>